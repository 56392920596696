import React from "react";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Link from "./link";
import { GatsbyImage } from "gatsby-plugin-image";
const ServiceCard = ({ img, imgAlt, title, url }) => {
	return (
		<Col xl={4} md={6}>
			<Card
				style={{
					boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.15)",
					borderRadius: "10px",
					border: "0px",
				}}
				className="w-100 mb-5  bg-background"
			>
				<div className="px-4 pt-4  bg-background">
					<Card.Img
						as={GatsbyImage}
						style={{ height: "269px" }}
						variant="top"
						image={img}
						alt={imgAlt}
					/>
				</div>
				<Card.Body>
					<Card.Title className="d-flex mb-4 px-0 text-primary justify-content-center">
						{title}
					</Card.Title>

					<Col xs={12} className="d-flex justify-content-center">
						<Button className="px-4 cta-btn" as={Link} to={url}>
							Read More
						</Button>
					</Col>
				</Card.Body>
			</Card>
		</Col>
	);
};

export default ServiceCard;
