import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Link from "./link";
import Button from "react-bootstrap/Button";
import { getImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";
import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";

const Hero = ({ title, description, type, backgroundImage, logo }) => {
	const isDarken = true;
	const pluginImage = getImage(backgroundImage);
	const StyledBackground = styled(BgImage)`
		&::before,
		&::after {
			filter: brightness(
				${({ isDarken }) => {
					return isDarken ? "60%" : "100%";
				}}
			);
		}
	`;
	return (
		<StyledBackground
			isDarken={isDarken}
			key={isDarken ? `dark` : `light`}
			Tag="section"
			className="py-8"
			image={pluginImage}
			alt="Hero Image"
		>
			<Container>
				<Row className=" pt-3 px-3 align-items-xl-end justify-content-center">
					<Col xs={12} md={7} className="pl-0 ps-md-3">
						<h1 className="pt-0 mb-3  text-white" id="services-hero-title">
							{title}
						</h1>

						<p className="mb-3 text-white ">{description}</p>
						<Button
							className="px-4 d-md-none w-100 w-md-auto"
							as={Link}
							href="tel:+441323642427"
						>
							Get a quote
						</Button>
						<Button
							className="px-4 d-none d-md-inline-block w-100 w-md-auto"
							as={Link}
							to="/contact-us"
						>
							Get a quote
						</Button>
					</Col>
					<Col
						className="text-center pb-4 pb-md-0"
						xs={{ span: 6, order: "first" }}
						md={{ span: 5, order: "last" }}
					>
						<GatsbyImage image={logo} alt="ECA Logo" />
					</Col>
				</Row>
			</Container>
		</StyledBackground>
	);
};

export default Hero;
