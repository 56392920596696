import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Link from "./link";

const CtaBanner = ({ headline, btnTxt, btnLink }) => {
	const ctaLink = btnLink || "/contact-us";

	return (
		<section
			style={{
				background: "linear-gradient(269.89deg, #EF4239 0.07%, #981A13 99.91%)",
			}}
			className={` py-5`}
		>
			<Container>
				<Row className="align-items-lg-center justify-content-center">
					<Col className="mb-4 mb-lg-0 text-start text-md-start">
						<h3 className="my-auto text-center text-lg-start text-white">
							{headline}
						</h3>
					</Col>
					<Col xs={12} lg="auto" className="text-center text-lg-start">
						<Button
							as={Link}
							variant="white"
							size="lg"
							className=" fw-bold px-4 w-100 w-md-auto cta-btn  white"
							to={ctaLink}
							id="cta-banner-button"
						>
							{btnTxt}
						</Button>
					</Col>
				</Row>
			</Container>
		</section>
	);
};

export default CtaBanner;
