import React from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

const LocalBusinessSchema = () => {
	const data = useStaticQuery(
		graphql`
			query {
				heroImg: wpMediaItem(title: { eq: "Hero-Image" }) {
					altText
					localFile {
						publicURL
						childImageSharp {
							original {
								height
								width
							}
							gatsbyImageData(
								formats: [AUTO, WEBP]
								quality: 100
								transformOptions: { cropFocus: CENTER, fit: CONTAIN }
								layout: CONSTRAINED
								placeholder: BLURRED
							)
						}
					}
				}
				reviews {
					reviewCount
				}
				schemaLogo: file(relativePath: { eq: "icon.png" }) {
					publicURL
				}
			}
		`
	);

	const reviewCount = data.reviews.reviewCount;
	const localBusinessSchema = {
		"@context": "https://schema.org",
		"@type": "Local Business",
		"@id": "https://denisbonnici.com/",
		name: "Denis Bonnici Electricians",
		legalName: "Denis Bonnici Electricians Ltd",
		description:
			"Denis Bonnici Electricians Ltd is a team of Electricians, established in Eastbourne for over 30 years.",
		url: "https://denisbonnici.com/",
		priceRange: "$$",
		areaServed: [
			{
				"@type": "City",
				name: "Eastbourne",
				sameAs: "https://en.wikipedia.org/wiki/Eastbourne",
			},
		],
		email: "info@example.co.uk",
		currenciesAccepted: "GBP",
		numberOfEmployees: "10",
		location: {
			"@type": "City",
			name: "Eastbourne",
			sameAs: "https://en.wikipedia.org/wiki/Eastbourne",
		},
		foundingLocation: {
			"@type": "City",
			name: "Eastbourne",
			sameAs: "https://en.wikipedia.org/wiki/Eastbourne",
		},
		slogan:
			"We aim to deliver the highest level of service and quality to both domestic and commercial clients.",
		telephone: "+441323642427",
		address: {
			"@type": "PostalAddress",
			addressLocality: "Eastbourne",
			addressRegion: "East Sussex",
			postalCode: "BN22 8HQ",
			streetAddress: "35b Clarence Road",
			addressCountry: "United Kingdom",
		},
		geo: {
			"@type": "GeoCoordinates",
			latitude: "0.2914997",
			longitude: "50.7769484",
		},
		hasMap: "https://goo.gl/maps/",
		openingHours: "Mo,Tu,We,Th,Fr 10:00-18:00",
		openingHoursSpecification: [
			{
				"@type": "OpeningHoursSpecification",
				closes: "18:00",
				dayOfWeek: "http://schema.org/Monday",
				opens: "10:00",
			},
			{
				"@type": "OpeningHoursSpecification",
				closes: "18:00",
				dayOfWeek: "http://schema.org/Tuesday",
				opens: "10:00",
			},
			{
				"@type": "OpeningHoursSpecification",
				closes: "18:00",
				dayOfWeek: "http://schema.org/Wednesday",
				opens: "10:00",
			},
			{
				"@type": "OpeningHoursSpecification",
				closes: "18:00",
				dayOfWeek: "http://schema.org/Thursday",
				opens: "10:00",
			},
			{
				"@type": "OpeningHoursSpecification",
				closes: "18:00",
				dayOfWeek: "http://schema.org/Friday",
				opens: "10:00",
			},
		],
		founder: {
			"@type": "Person",
			name: "Denis Bonnici",
			id: "https://denisbonnici.com/about-us/",
			url: "https://denisbonnici.com/about-us/",
			description: "placeholder text",
		},
		aggregateRating: {
			"@type": "AggregateRating",
			bestRating: "5",
			worstRating: "1",
			ratingValue: "5",
			reviewCount: `${reviewCount}`,
			url: "https://denisbonnici.com/reviews",
		},
		contactPoint: {
			"@type": "ContactPoint",
			"@id": "https://denisbonnici.com/contact-us",
			url: "https://denisbonnici.com/contact-us",
			// "image": `${data.schemaLogo.publicURL}`,
			name: "Contact Us",
			description: "Placeholder text",
			telephone: "+441323642427",
			contactType: "Placeholder text",
			availableLanguage: "en",
			areaServed: [
				{
					"@type": "City",
					name: "Eastbourne",
					sameAs: "https://en.wikipedia.org/wiki/Eastbourne",
				},
			],
		},
		logo: ``,
		image: ``,
		sameAs: [
			"https://www.facebook.com/DenisBonniciElectricians/",
			"https://twitter.com/denisbonnici",
			"https://www.instagram.com/dbelectricians/",
			"https://www.linkedin.com/company/dbelectricians/",
		],
	};

	return (
		<Helmet>
			<script type="application/ld+json">
				{JSON.stringify(localBusinessSchema)}
			</script>
		</Helmet>
	);
};

export default LocalBusinessSchema;
