import { useStaticQuery, graphql } from "gatsby";
import * as React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../components/layout";
import Hero from "../components/hero-home";
import ServiceCard from "../components/service-card";
import ContactForm from "../components/contactForm";
import CtaBanner from "../components/cta-banner";
import ReviewSection from "../components/review-section";
import Certifications from "../components/certifications";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import LocalBusinessSchema from "../components/schema/localBusinessSchema";
import { Helmet } from "react-helmet";

const IndexPage = () => {
	const data = useStaticQuery(graphql`
		query {
			heroImg: wpMediaItem(title: { eq: "Hero-Image" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			ECAImg: wpMediaItem(title: { eq: "ECA-Logo" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: TRACED_SVG
						)
					}
				}
			}
			MaintenanceImg: wpMediaItem(title: { eq: "Maintenance-Image-New" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			CUImg: wpMediaItem(title: { eq: "Consumer-Unit-Box-Image" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			EHIImg: wpMediaItem(title: { eq: "Quantum-storage-heater-Image" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			EICRImg: wpMediaItem(title: { eq: "EICR-Image-New" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			FAELImg: wpMediaItem(
				title: { eq: "Fire-Alarm-Emergency-Lighting-Image" }
			) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			NimbusImg: wpMediaItem(title: { eq: "DBE-meter-new" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}

			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;
	const heroImg = data.heroImg?.localFile.childImageSharp.gatsbyImageData;
	const MaintenanceImg =
		data.MaintenanceImg?.localFile.childImageSharp.gatsbyImageData;
	const CUImg = data.CUImg?.localFile.childImageSharp.gatsbyImageData;
	const EHIImg = data.EHIImg?.localFile.childImageSharp.gatsbyImageData;
	const EICRImg = data.EICRImg?.localFile.childImageSharp.gatsbyImageData;
	const FAELImg = data.FAELImg?.localFile.childImageSharp.gatsbyImageData;
	const NimbusImg = data.NimbusImg?.localFile.childImageSharp.gatsbyImageData;
	const ECAImg = data.ECAImg?.localFile.childImageSharp.gatsbyImageData;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
				},
			},
		],
	};

	return (
		<Layout>
			<Helmet>
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
				<script
					src="https://www.google.com/recaptcha/api.js"
					async
					defer
				></script>
			</Helmet>
			<LocalBusinessSchema />
			<GatsbySeo
				title="Electricians Eastbourne | Denis Bonnici Electricians Ltd"
				description="Denis Bonnici Electricians Ltd is a team of Electricians, established in Eastbourne for over 30 years."
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}`,
					title: "Home | Denis Bonnici Electricians Ltd",
					description:
						"Denis Bonnici Electricians Ltd is a team of Electricians, established in Eastbourne for over 30 years.",
					images: [
						{
							url: `${data.heroImg?.localFile.publicURL}`,
							width: `${data.heroImg?.localFile.childImageSharp.original.width}`,
							height: `${data.heroImg?.localFile.childImageSharp.original.height}`,
							alt: `${data.heroImg?.altText}`,
						},
					],
				}}
				twitter={{
					handle: "@denisbonnici",
					site: "@denisbonnici",
					cardType: "summary",
				}}
			/>
			<Hero
				logo={ECAImg}
				title="Denis Bonnici Electricians Ltd offers quality electrical services"
				description="Denis Bonnici Electricians Ltd is a team of Electricians, established in Eastbourne for over 30 years. We aim to deliver the highest level of service and quality to both domestic and commercial clients."
				backgroundImage={heroImg}
			/>
			<section className="py-7 py-md-8 py-lg-9">
				<Certifications />
			</section>
			<section id="services" className="pb-7 pb-md-8 pb-lg-9">
				<Container>
					<Row>
						<Col>
							<h1 className="text-center mb-1">Services</h1>
							<hr
								className="bg-primary mx-auto mt-0"
								style={{ height: "2px", width: "6rem" }}
							/>
						</Col>
					</Row>
					<Row className="pt-4">
						<ServiceCard
							title="Maintenance"
							url="/services/maintenance"
							img={MaintenanceImg}
							imgAlt={data.MaintenanceImg?.altText}
						/>
						<ServiceCard
							title="Consumer unit"
							url="/services/consumer-unit"
							img={CUImg}
							imgAlt={data.CUImg?.altText}
						/>
						<ServiceCard
							title="Electric Heating Installation"
							url="/services/electric-heater-installations"
							img={EHIImg}
							imgAlt={data.EHIImg?.altText}
						/>
						<ServiceCard
							bgPos="top"
							title="Electrical Installation Condition Report"
							url="/services/electrical-installation-condition-report"
							img={EICRImg}
							imgAlt={data.EICRImg?.altText}
						/>
						<ServiceCard
							title="Fire Alarm / Emergency Lighting"
							url="/services/fire-alarms-and-emergency-lighting"
							img={FAELImg}
							imgAlt={data.FAELImg?.altText}
						/>
						<ServiceCard
							title="Smart Prepayment / Card Meters"
							url="/services/smart-prepayment-and-card-meters"
							img={NimbusImg}
							imgAlt={data.NimbusImg?.altText}
						/>
					</Row>
				</Container>
			</section>
			<section>
				<CtaBanner
					headline="30 years of professional electrical services in Eastbourne"
					btnTxt="Get a quote"
				/>
			</section>
			<section className="py-6">
				<ReviewSection />
			</section>
			<section className="pb-7">
				<ContactForm />
			</section>
		</Layout>
	);
};

export default IndexPage;
